import { DefaultPrivacyLevel } from '@datadog/browser-rum';
import { EnvironmentInterface } from './environment-interface';

// This file represents the base environment settings for the application.
// Each env specific file (e.g. environment.prod.ts) should import this file and override the values as needed.
// values that a need for that environment.
export const environment: EnvironmentInterface = {
    title: 'Lockbox',
    production: false,
    logLevel: 'DEBUG',

    enableConsoleLogging: false,

    logoUrl: 'assets/logo/lockbox_logo.png',
    // baseUrl: 'http://localhost:8080', // Local
    baseUrl: 'https://qa-api-admin.lockboxai.com', // QA
    clientURL: 'https://qa.lockboxai.com/home',

    applicationVersion: 'prod2-32',
    versionCheckInterval: 300, // 5 minutes

    datadogApplicationId: 'b9179cd4-9cd9-4ddf-955c-dcf1d60fc301',
    datadogClientToken: 'puba794a68362d7d2aa9945c2edb83b1dea',
    datadogEnv: 'qa',
    datadogService: 'angular-admin-frontend',
    datadogSite: 'datadoghq.com',
    datadogSessionSampleRate: 100,
    datadogSessionReplaySampleRate: 100,
    datadogTrackResources: true,
    datadogTrackLongTasks: true,
    datadogTrackUserInteractions: true,
    datadogDefaultPrivacyLevel: DefaultPrivacyLevel.MASK_USER_INPUT,
    datadogTraceSampleRate: 100,
    datadogCompressIntakeRequests: true,
    // Reference: https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm#contexts-life-cycle
    datadogStoreContextsAcrossPages: true,
    allowLocalLogin: false,
    flutterLoginURL: '',
    templateVersion: 'v1',
    wsUrl: 'wss://qa-api-admin.lockboxai.com/websocket',
    allowFlutterLogin: true
};
