import {
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';

@Component({
    selector: 'rh-sidecar',
    templateUrl: './rh-sidecar.component.html',
    styleUrls: ['rh-sidecar.component.scss'],
})
export class RightHandSideCarComponent {
    private readonly rightArrowEntity = '&#9654;';
    private readonly leftArrowEntity = '&#9664;';

    @Input() open = false;

    @HostBinding('class.collapsed') get isCollapsed() {
        return !this.open;
    }

    @ViewChild('sidecar_button') sidecarButton!: ElementRef;

    toggleSidecar() {
        this.open = !this.open;

        if (this.open) {
            this.sidecarButton.nativeElement.innerHTML = this.rightArrowEntity;
        } else {
            this.sidecarButton.nativeElement.innerHTML = this.leftArrowEntity;
        }
    }
}
